@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply overflow-x-hidden;
}
.bg-primary-dark {
	background-color: #70551f;
}
.bg-primary {
	background-color: #977434;
}
.border-primary {
	border-color: #977434;
}
.text-primary {
	color: #977434;
}
.text-primary-light {
	color: #e0aa3e;
}
.text-primary-dark {
	color: #70551f;
}

.bg-primary-gradient {
	@apply bg-gradient-to-b from-[#E0AA3E] to-[#ECCA88];
}
.bg-primary-light {
	background-color: #e0aa3e;
}
.border-primary-light {
	border-color: #e0aa3e;
}

.bg-secondary {
	background-color: #013763;
}

h1 {
	@apply text-5xl;
}

h2 {
	@apply text-4xl;
}

h3 {
	@apply text-3xl;
}

h4 {
	@apply text-2xl;
}

h5 {
	@apply text-xl;
}

h6 {
	@apply text-lg;
}

p {
	@apply text-base;
}

a {
	padding: 0;
	margin: 0;
}

label {
	@apply text-xl mt-4;
}

.post-content * {
	line-height: 3 !important;
}

.leading-relaxed-important {
	line-height: 33px !important;
}

@font-face {
	font-family: 'Lighthouse';
	src: url('../public/assets/fonts/Lighthouse.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
.font-lighthouse {
	font-family: 'Lighthouse', sans-serif;
}

.input-main {
	@apply border border-black p-2 text-xl text-black my-2;
}
.btn-outline {
	@apply text-white border border-white rounded-full py-2 px-4 transition duration-300;
}
.btn-primary {
	@apply text-white rounded-lg py-2 px-4 transition duration-300 bg-primary-light text-xl text-center cursor-pointer;
}
.nav-link {
	@apply hover:text-gray-400 flex items-center flex-nowrap gap-2;
}

.swiper-button-next,
.swiper-button-prev {
	color: gray !important; /* Mengubah warna panah menjadi putih */
	width: 40px; /* Lebar panah */
	height: 40px; /* Tinggi panah */
	fill: gray !important;
	stroke: gray !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
	opacity: 0.8; /* Mengubah opasitas saat hover */
}

.DialogOverlay {
	z-index: 999;
	background-color: black;
	opacity: 0.5;
	position: fixed;
	inset: 0;
}

.DialogContent {
	z-index: 999;
	background-color: white;
	border-radius: 6px;
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90vw;
	max-width: 520px;
	max-height: 85vh;
	padding: 25px;
	animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent:focus {
	outline: none;
}

.DialogTitle {
	margin: 0;
	font-weight: 500;
	color: var(--mauve-12);
	font-size: 17px;
}

.DialogDescription {
	margin: 10px 0 20px;
	color: var(--mauve-11);
	font-size: 15px;
	line-height: 1.5;
}

.Button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 15px;
	line-height: 1;
	font-weight: 500;
	height: 35px;
}
.Button.violet {
	background-color: white;
	color: var(--violet-11);
	box-shadow: 0 3px 10px var(--black-a7);
}
.Button.violet:hover {
	background-color: var(--mauve-3);
}
.Button.violet:focus {
	box-shadow: 0 0 0 3px black;
}
.Button.green {
	background-color: var(--green-4);
	color: var(--green-11);
}
.Button.green:hover {
	background-color: var(--green-5);
}
.Button.green:focus {
	box-shadow: 0 0 0 3px var(--green-7);
}

.IconButton {
	font-family: inherit;
	border-radius: 100%;
	height: 25px;
	width: 25px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--violet-11);
	position: absolute;
	top: 10px;
	right: 10px;
}
.IconButton:hover {
	background-color: var(--violet-4);
}
.IconButton:focus {
	box-shadow: 0 0 0 3px var(--violet-7);
}

@keyframes overlayShow {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes contentShow {
	from {
		opacity: 0;
		transform: translate(-50%, -48%) scale(0.96);
	}
	to {
		opacity: 1;
		transform: translate(-50%, -50%) scale(1);
	}
}

/* maps */

.st0 {
	fill: #f9eed880;
	stroke-miterlimit: 10;
}

.st1 {
	font-family: 'MyriadPro-Bold';
}

.st2 {
	font-size: 24px;
}

.st3 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st4 {
	/* font-family: "Open Sans"; */
	fill: white;
}

.st5 {
	font-size: 13px;
	fill: white;
}

/* .text-map {
	@apply text-white bg-white !important;
} */

.st6 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st7 {
	fill: #ffffc6;
	stroke-miterlimit: 10;
}

.st8 {
	fill: #eec3fe;
	stroke-miterlimit: 10;
}

.st9 {
	fill: #345797;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st10 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st11 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st12 {
	fill: #fbc5c5;
	stroke-miterlimit: 10;
}

.st13 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st14 {
	fill: #ffffc6;
	stroke-miterlimit: 10;
}

.st15 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st16 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st17 {
	fill: #f4c99a;
	stroke-miterlimit: 10;
}

.st18 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st19 {
	fill: #d0ffc6;
	stroke-miterlimit: 10;
}

.st20 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st21 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st22 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st23 {
	fill: #ff6666;
	stroke-miterlimit: 10;
}

.st24 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st25 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st26 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st27 {
	fill: #345797;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st28 {
	fill: #fbc5c5;
	stroke-miterlimit: 10;
}

.st29 {
	fill: #fbc5c5;
	stroke-miterlimit: 10;
}

.st30 {
	fill: #345797;
	stroke-miterlimit: 10;
	stroke: #ccb096;
	stroke-width: 3px;
}

.st31 {
	fill: #8c8d82;
	stroke-miterlimit: 10;
}

.st32 {
	font-size: 20px;
}

path:hover {
	fill: #013763;
}

.content p {
	font-size: 1.5rem;
}

.innerhtml {
	font-size: 1.5rem;
	p {
		margin-bottom: 1rem;
	}
	ol {
		li {
			font-size: 1rem;
		}
		margin-left: 2rem;
		list-style-type: disc;
	}
}
